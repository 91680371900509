<template>
  <div class="app-container query-rank">
    <h4 class="title">位次查询</h4>
    <div class="head">
      <div class="left head-item">
        <div class="left-title">查询省份：</div>
        <div class="main">
          {{ provinceObj.ProvinceName }}

          <!-- <el-select v-model="provinceValue" placeholder="请选择省份" @change="selectProvince">
            <el-option v-for="(item, index) in provinceList" :key="index" :value="item.ID"
              :label="item.ProvinceName"></el-option>
          </el-select> -->
        </div>
      </div>
      <div class="right head-item">
        <div class="left-title">查询年份：</div>
        <div class="main">
          <el-select v-model="year" @change="selectYear" placeholder="请选择年份">
            <el-option v-for="(item, index) in yearList" :key="index" :value="item" :label="item"></el-option>
          </el-select>
        </div>
      </div>
      <div class="center head-item">
        <template v-if="!isNewExam">
          <div class="center-title">科类：</div>
          <div class="main">
            <el-radio-group v-model="subjectType" @change="handleSelectSubject">
              <el-radio :label="1">文科</el-radio>
              <el-radio :label="2">理科</el-radio>
            </el-radio-group>

          </div>
        </template>
        <template v-else-if="isNewExam && newExamStatus">
          <div class="right-title">科目：</div>
          <div class="main">
            <el-radio-group v-model="selectSubject" @change="handleSelectSubject">
              <el-radio label="物理">物理</el-radio>
              <el-radio label="历史">历史</el-radio>
            </el-radio-group>

          </div>
        </template>
      </div>
      
    </div>
    <div class="main">
      <div class="input-score">
        <div class="input-score-title">
          预计总分：
        </div>
        <el-input placeholder="请输入分数" v-model="inputScore">
          <template slot="append">分</template>
        </el-input>
      </div>
      <div class="query-btn">
        <el-button class="query-btn-item" type="primary" @click="getRank" :loading="loading">查询位次</el-button>
      </div>





      <div class="box">
        <div class="box-title">位次查询结果</div>
        <div class="info">
          <span>查询条件：</span>
          <div class="info-item info-province">{{ infoName.provinceName }}</div>
          <div v-show="infoName.subjectName" class="info-item info-subject">{{ infoName.subjectName }}</div>
          <div v-show="infoName.yearName" class="info-item info-year">{{ infoName.yearName }}年</div>
          <div v-show="infoName.scoreName" class="info-item info-score">{{ infoName.scoreName }}分</div>
        </div>
        <div class="show-score">
          <div class="show-score-top show-score-item">
            <span>位次区间：</span>
            <span class="score">{{ rankData.RankLow }}-{{ rankData.RankHigh }}</span>
          </div>
          <div class="show-score-bottom show-score-item">
            <span>建议位次：</span>
            <span class="score">{{ rankData.Rank }}</span>
          </div>
        </div>
      </div>




      <div id="line_echarts" class="line-echarts"></div>

      <div class="rank-title">一分一段表：</div>
      <div class="rank-list">
        <el-table :data="rankList" border stripe
          :header-cell-style="{ background: 'rgba(var(--themecolor),0.75)', color: '#fff', 'text-align': 'center' }">
          <el-table-column align="center" label="分数" prop="Score"></el-table-column>
          <el-table-column align="center" label="人数" prop="Persons"></el-table-column>
          <el-table-column align="center" label="累计人数" prop="TotalPersons"></el-table-column>
        </el-table>
      </div>
      <!-- <div class="spare">暂无数据...</div> -->
    </div>
    <!-- <el-backtop></el-backtop> -->
  </div>
</template>

<script>
// import { ProvinceList, LineRank, LineRankYears, LineRankList } from '@/api/common'
import API from '@/api/config'
import {areaData} from '@/utils/data';
import { getStore } from '@/utils/utils';

export default {
  name: "query-rank",
  data() {
    return {
      provinceValue: '',
      subjectType: 1,
      selectSubject: '物理',
      inputScore: '',
      provinceList: [],
      year: '',
      loading: false,

      //新高考状态
      isNewExam: '',
      newExamStatus: '',

      rankData: '',

      yearList: [],
      // 一分一段表
      rankList: [],

      infoName: {
        provinceName: '',
        subjectName: '',
        yearName: '',
        scoreName: ''
      },

      provinceObj: {}
    }
  },
  computed: {

  },
  created() {
    this.init()
  },
  mounted() {
    this.handleEcharts()
  },
  methods: {
    // 选择科类
    handleSelectSubject(value) {
      let params = {
        ProvinceId: areaData[this.provinceValue],
        Year: this.year
      }
      // 新老高考判断
      this.isNewExam ? params.SelectSubject = value : params.SubjectType = value
      this.getRankList(params)

    },
    // 选择年份
    selectYear(value) {
      this.year = value
      //新高考状态判断
      this.judgeNewExamStatus()
    },
    //选择省份
    selectProvince(id) {
      
      this.getYearList(id).then(() => {
        this.judgeNewExamStatus()
      })
    },
    //初始化数据
    init() {
      let temp = getStore('OrgProvince')
      if (temp) {
        this.provinceObj = JSON.parse(temp)
      }
      this.provinceValue = this.provinceObj.ProvinceId
      this.getYearList(this.provinceValue).then(() => {
          //新高考状态判断
          this.judgeNewExamStatus()
        })
      // API.Public.ProvinceList().then(res => {
      //   this.provinceList = res.data
      //   this.provinceValue = this.provinceList[0].ID;

      //   this.getYearList(this.provinceValue).then(() => {
      //     //新高考状态判断
      //     this.judgeNewExamStatus()
      //   })
      // })
    },
    getRank() {
      const isThis = this
      this.loading = true
      if ((!isThis.provinceValue) || (!isThis.subjectType) || (!isThis.inputScore)) {
        this.$message({
          message: '请先填入基本信息',
          type: 'info',
          showClose: true,
          center: true
        })
        this.loading = false
      } else {
        let query = {}
        if (isThis.isNewExam) {
          query = {
            ProvinceId: isThis.provinceValue,
            SelectSubject: isThis.selectSubject,
            Score: isThis.inputScore,
            Year: isThis.year
          }
        } else {
          query = {
            ProvinceId: isThis.provinceValue,
            SubjectType: isThis.subjectType,
            Score: isThis.inputScore,
            Year: isThis.year
          }
        }

        // 获取位次信息
        API.Public.LineRank(query).then(res => {
          isThis.rankData = res.data

          // 更新显示信息
          isThis.infoName.yearName = isThis.year
          isThis.infoName.scoreName = isThis.inputScore

          isThis.provinceList.some(item => {
            if (isThis.provinceValue === item.ID) {
              isThis.infoName.provinceName = item.ProvinceName
              return true
            }
          })
          if (isThis.isNewExam ) {
            isThis.infoName.subjectName = isThis.selectSubject
          } else {
            isThis.infoName.subjectName = isThis.subjectType*1 === 1 ? '文科':'理科'
          }
          this.loading = false
        })
      }
    },
    //
    judgeNewExamStatus() {
      this.$store.dispatch('user/judgeNewExam', { id: areaData[this.provinceValue], year: this.year }).then(newExam => {
        let params = {
          ProvinceId: areaData[this.provinceValue],
          Year: this.year,
        }
        this.isNewExam = newExam.IsNewExam
        if (newExam.IsNewExam) {
          newExam.RuleList.length > 1 ? this.newExamStatus = true : this.newExamStatus = false
          this.newExamStatus ? this.selectSubject = '物理' : this.selectSubject = ''


          params.SelectSubject = this.selectSubject // 新高考选科参数默认第一个
        } else {
          params.SubjectType = this.subjectType || 1 // 老高考选科参数默认第一个
        }

        this.getRankList(params)
      })
    },
    // 获取年份列表
    async getYearList(id) {
      let res = await API.Public.LineRankYears({ ProvinceId: areaData[id] })
      this.yearList = res.data
      this.year = res.data[0]
      return
    },
    // 一份一段表
    async getRankList(params) {
      let res = await API.Public.LineRankList(params)
      // 分数
      let finalData = res.data.map(item => {
        return [
          item.Score,
          item.Persons,
          item.RankHigh + '-' + item.RankLow
        ]
      })
      // let yData = res.data.map(item => item.Persons)

      this.handleEcharts(finalData)
      this.rankList = res.data
      return res
    },
    // echarts 
    handleEcharts(finalData) {

      // 初始化饼图配置
      let OEcharts = this.$echarts.init(document.getElementById('line_echarts'))
      let option = {
        title: {
          // text: '折现展示数据'
        },
        tooltip: {
          show: true,
          trigger: 'axis',
          backgroundColor: 'rgba(0,0,0,.5)',
          textStyle: {
            color: '#ffffff'
          },
          formatter(params) { // 返回series配置项中的对应数据
            return '当前：' + params[0].data[0] + '分， ' + params[0].data[1] + '人' + '<br />'
              + '位次：' + params[0].data[2]
          }
        },
        legend: {
          
        },
        xAxis: [{
          type: "category",
          name: '分数',

        }],
        yAxis: [{
          type: "value",
          name: '人数',
        }],
        series: [{
          type: 'line',
          data: finalData,
          showSymbol: false,

          lineStyle: {
            width: 1.5
          },

          emphasis: {
            lineStyle: {
              width: 1.5
            }
          }
        }],
      }
      OEcharts.setOption(option)
    }
  }
}
</script>

<style scoped lang="less">
// @import "~@/styles/base.scss";

.query-rank {
  padding: 2rem 0;

  .title {
    text-align: center;
    color: rgb(var(--themecolor));
    font-size: 20px;
  }

  .head {
    display: flex;
    justify-content: flex-start;
    margin: 2rem 0;

    .head-item {
      margin-right: 6rem;

      display: flex;
      align-items: center;
    }

    .left {
      .left-title {
        margin: 0 5px;
      }

      .main {}
    }

    .center {
      .center-title {}

      .main {}
    }

    .right {}

  }

  .main {
    .input-score {
      display: flex;
      align-items: center;

      .input-score-title {
        width: 6rem;
        text-align: center;
      }
    }

    .query-btn {
      text-align: center;
      padding: 2rem;

      .query-btn-item {
        width: 6.8rem;
        background-color: rgba(var(--themecolor),0.75);
        border-color: rgba(var(--themecolor),0.75);
      }
    }

    .box {
      padding: 20px;
      margin-bottom: 50px;

      background-color: #fff8db;
      .box-title {
        text-align: center;
        font-size: 18px;
      }
      .info {
        display: flex;
        justify-content: flex-start;

        .info-item {
          margin-right: 20px;
          color: rgb(var(--themecolor));
        }
      }
    }
    .show-score {
      padding: 3rem 0;
      display: flex;
      justify-content: center;

      .show-score-item {
        padding: 1rem 0;
        margin-right: 100px;

        .score {
          color: rgb(var(--themecolor));
          font-size: 18px;
        }
      }

      .show-score-top {}

      .show-score-bottom {}
    }

    .line-echarts {
      width: 100%;
      height: 400px;
      margin-bottom: 40px;
      border: 1px solid #000;
    }

    .rank-title {
      padding-left: 15px;
      margin-bottom: 10px;
      font-size: 24px;
      position: relative;

      &::before {
        content: '';
        width: 4px;
        height: 30px;
        background-color: rgb(var(--themecolor));
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
</style>
<style lang="less">
.query-rank .el-range-editor.is-active, .query-rank .el-range-editor.is-active:hover, .query-rank .el-select .el-input.is-focus .el-input__inner{
  border-color: rgba(var(--themecolor),0.75);
}
.query-rank .el-select .el-input__inner:focus,.query-rank .el-input__inner:focus{
  border-color: rgba(var(--themecolor),0.75);
}
.el-select-dropdown__item.selected{
  color: rgba(var(--themecolor),0.75);
}
.el-radio__input.is-checked .el-radio__inner{
  border-color: rgba(var(--themecolor),0.75);
  background: rgba(var(--themecolor),0.75);
}
.el-radio__input.is-checked + .el-radio__label{
  color: rgba(var(--themecolor),0.75);
}
</style>